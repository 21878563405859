.brand-color {
    color: var(--brand-color) !important;
}

.brand-text-color {
    color: var(--brand-text-color) !important;
}

.red-color {
    color: red !important;
}

.grey-light-color {
    color: #AAAAAA !important;
}

.grey-dark-color {
    color: #5A5A5A !important;
}

.grey-darker-color {
    color: #1B2735 !important;
}

.brand-button {
    --background: var(--brand-color);
    --background-activated: transparent;
    --background-hover: #fff;
    --background-focused: #fff;
    --background-activated-opacity: 0;
    --background-focused-opacity: .24;
    --background-hover-opacity: .08;
    --color: var(--brand-text-color);
    --color-hover: var(--brand-text-color);
}

.gray-rounded-button {
    --padding-top: 0.4rem;
    --padding-bottom: 0.4rem;
    --padding-start: 1.2em;
    --padding-end: 1.2em;
    --border-radius: 16px;
    --background: #D0D0D0;
    --background-activated: transparent;
    --background-hover: #fff;
    --background-focused: #fff;
    --background-activated-opacity: 0;
    --background-focused-opacity: .24;
    --background-hover-opacity: .08;
    --color: var(--brand-color);
    --color-hover: var(--brand-color);
}

.gold-rounded-button {
    @extend .gray-rounded-button;
    --background: #A59C00;
    --background-hover: #c9c024;
    --background-focused: #c9c024;
    --color: #fff;
    --color-hover: #fff;
}

.brand-link {
    color: #000;
    text-decoration: none;
}

.small-text {   
    font-size: 0.8rem !important;
}

.normal-text {
    font-size: 0.8rem !important;
}

.font-0-46 {
    font-size: 0.46rem !important;
}

.font-0-5 {
    font-size: 0.5rem !important;
}

.font-0-6 {
    font-size: 0.6rem !important;
}

.font-0-7 {
    font-size: 0.7rem !important;
}

.font-0-8 {
    font-size: 0.8rem !important;
}

.font-0-9 {
    font-size: 0.9rem !important;
}

.font-1 {
    font-size: 1rem !important;
}

.font-bold {
    font-weight: 600 !important;
}

.font-bolder {
    font-weight: 900 !important;
}

.text-transform-none {
    text-transform: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.brand-toolbar-header {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: var(--brand-text-color) solid 1px;

    .brand-toolbar {
        --ion-toolbar-color: var(--brand-color);
        --color: var(--brand-color);
    }
}

.ios {
    .brand-toolbar-header {
        .brand-toolbar {
            --ion-toolbar-color: var(--brand-color);
            --ion-toolbar-background: #fff;
            --background: #fff;
            --color: var(--brand-color);
            --border-color: var(--brand-text-color);

            background: #fff;

            :host {
                .toolbar-background {
                    margin: 0 10px;
                }
            }
        }
    }
}

.vertical-align-middle {
    vertical-align: middle;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.force-opacity-1 {
    opacity: 1;
}